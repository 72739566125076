import Axios from "axios";
import {urlFull} from "@/services/config";

const customStoreService = {
  getReportsByModule(module, startDate, endDate, limit) {
    return Axios.get(urlFull(`/${module}?startDate=${startDate}&endDate=${endDate}&limit=${limit}`)).then((res) => res.data);
  },
  getDataByModule(module, search = "", page = 0, itemsPerPage = 10, startDate = "", endDate = "", embedded = "", order) {
    return Axios.get(urlFull(`/${module}?search=${search}&page=${page}&pageSize=${itemsPerPage}&startDate=${startDate}&endDate=${endDate}&embedded=${embedded}${order ? `&order=${order}` : ''}`)).then((res) => res.data);
  },
  getDataByModuleAndParam(module, search = "", page = 0, itemsPerPage = 10, startDate = "", endDate = "", embedded = "", additionalParams, status = "", order = "") {
    let urlParams = null
    let paramToString = ""
    let newParam = ""
    if (status) {

      urlParams = new URLSearchParams({
        search, page, pageSize: itemsPerPage, startDate, endDate, embedded, ...additionalParams,
      });
      paramToString = `${urlParams.toString()}`
      paramToString.concat(status)
      newParam = `${urlParams.toString()}${status}`
      if (order) {
        newParam = `${newParam}&${order}`
      }
    } else {
      if (!order) {
        urlParams = new URLSearchParams({
          search, page, pageSize: itemsPerPage, startDate, endDate, embedded, ...additionalParams
        });
        paramToString = urlParams.toString()
      } else {
        urlParams = new URLSearchParams({
          search, page, pageSize: itemsPerPage, startDate, endDate, embedded, ...additionalParams
        });
        paramToString = `${urlParams.toString()}&${order}`
      }
    }
    return Axios.get(urlFull(`/${module}?${newParam ? newParam : paramToString}`)).then((res) => res.data);
  },
  getDataByModuleAndParamWithOutPage(module, additionalParams) {
    const urlParams = new URLSearchParams({
      ...additionalParams,
    });
    return Axios.get(urlFull(`/${module}?${urlParams.toString()}`)).then((res) => res.data);
  },
  getDataByModuleAndIdAndParamWithOutPage(module, id, additionalParams) {
    const urlParams = new URLSearchParams({
      ...additionalParams,
    });
    return Axios.get(urlFull(`/${module}/${id}?${urlParams.toString()}`)).then((res) => res.data);
  },
  getDataByProperty(module, property = '', elementId = null) {
    if (!elementId) {
      return Promise.resolve()
    }
    return Axios.get(urlFull(`/${module}?${property}=${elementId}`)).then((res) => res.data);
  },
  deleteByModule(module, data) {
    return Axios.delete(urlFull(`/${module}/${data.uuid}`)).then((res) => res.data);
  },
  deleteByModuleBody({module, data}) {
    return Axios.delete(urlFull(`/${module}`), {data}).then((res) => res.data);
  },
  getDataCrudByModule(module, uuid) {
    return Axios.get(urlFull(`/${module}/${uuid}`)).then((res) => res.data);
  },
  getDataByModuleSimple(module) {
    return Axios.get(urlFull(`/${module}`)).then((res) => res.data);
  },
  getDataCrudByModuleAndParam(module, uuid, param) {
    return Axios.get(urlFull(`/${module}?${param}=${uuid}`)).then((res) => res.data);
  },
  postDataByModule(module, data) {
    return Axios.post(urlFull(`/${module}`), data).then((res) => res.data);
  },
  postImageByOwner(ownerId, formData) {
    return Axios.post(urlFull(`/storage/owner/${ownerId}`), formData).then((res) => res.data);
  },
  getImagesByOwner(ownerId) {
    return Axios.get(urlFull(`/storage/owner/${ownerId}`)).then((res) => res.data);
  },
  deleteFileByOwner(ownerId, data) {
    return Axios.delete(urlFull(`/storage/owner/${ownerId}/${data.name}`)).then((res) => res.data);
  },
  postAdditionalDriver(data) {
    return Axios.post(urlFull(`/additional-drivers`), data).then((res) => res.data);
  },
  postDataByModuleAndUrl(module, uuid) {
    return Axios.post(urlFull(`/${module}/${uuid}`)).then((res) => res.data);
  },
  getPdfRegister(uuid, name) {
    return Axios.get(urlFull(`/patients/pdf/${uuid}`), {responseType: 'blob'})
      .then((res) => {
        this.blobToFileAndDownload(res, `${name}.pdf`);
      })
      .catch((error) => {
        console.error("Hubo un error al descargar el archivo:", error);
      });
  },
  downloadInventory(name, storeIds) {
    let endpoint = `/inventory/download`;
    if (storeIds && storeIds.length > 0) {
      const queryParams = storeIds.map(storeId => `storeId=${encodeURIComponent(storeId)}`).join('&');
      endpoint += `?${queryParams}`;
    }
    return Axios.get(urlFull(endpoint), {responseType: 'blob'})
      .then((res) => {
        this.blobToFileAndDownload(res, `${name}.xlsx`);
      })
      .catch((error) => {
        console.error("Hubo un error al descargar el archivo:", error);
      });
  },
  uploadProducts(formData) {
    return Axios.post(urlFull(`/products/upload`), formData).then((res) => res.data);
  },
  uploadInventoryStores(formData) {
    return Axios.post(urlFull(`/inventory-stores/upload`), formData).then((res) => res.data);
  },
  uploadStoreStocks(formData) {
    return Axios.post(urlFull(`/store-stocks/upload`), formData).then((res) => res.data);
  },
  uploadPatientStocks(formData) {
    return Axios.post(urlFull(`/patient-stocks/upload`), formData).then((res) => res.data);
  },
  downloadInventoryOrder(name, storeIds) {
    let endpoint = `/inventory-orders/download`;
    if (storeIds && storeIds.length > 0) {
      const queryParams = storeIds.map(storeId => `storeId=${encodeURIComponent(storeId)}`).join('&');
      endpoint += `?${queryParams}`;
    }
    return Axios.get(urlFull(endpoint), {responseType: 'blob'})
      .then((res) => {
        this.blobToFileAndDownload(res, `${name}.xlsx`);
      })
      .catch((error) => {
        console.error("Hubo un error al descargar el archivo:", error);
      });
  },
  generalDownloadFile(module, name, type='xlsx', storeIds=[]) {
    if (storeIds && storeIds.length > 0) {
      const queryParams = storeIds.map(storeId => `storeId=${encodeURIComponent(storeId)}`).join('&');
      module += `?${queryParams}`;
    }
    return Axios.get(urlFull(module), {responseType: 'blob'})
        .then((res) => {
          this.blobToFileAndDownload(res, `${name}.${type}`);
        })
        .catch((error) => {
          console.error("Hubo un error al descargar el archivo:", error);
        });
  },
  blobToFileAndDownload(res, filename) {
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  }
};

export default customStoreService;
