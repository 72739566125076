<template>
  <v-row class="mt-4" no-gutters>
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <title-info-card icon="mdi-google-spreadsheet" textTitle="Kardex">
            <template #contentCard>
              <v-form v-model="validForm" @input="inputForm">
                                <v-row class="mt-3">
                                  <!--                  seccion de producto y cantidad-->
                                  <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                    <div>
                                      <label class="input-label" for="product">Producto<span
                                          class="required-field-form">*</span></label>
                                      <v-autocomplete
                                          v-model="currentElement.productId"
                                          id="product"
                                          :items="products"
                                          :rules="required"
                                          item-value="uuid"
                                          item-text="name"
                                          hide-details
                                          outlined
                                          color="backofficeInput"
                                          placeholder="Selecciona un producto"
                                          @change="getProductUnits"
                                      />
                                    </div>
                                  </v-col>
                                  <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                    <div>
                                      <label class="input-label" for="phone">Unidad<span
                                          class="required-field-form">*</span></label>
                                      <v-select
                                          v-model="currentElement.productUnitId"
                                          id="phone"
                                          :items="productUnits"
                                          :rules="required"
                                          item-value="uuid"
                                          item-text="unit.name"
                                          hide-details
                                          outlined
                                          color="backofficeInput"
                                          placeholder="Selecciona una unidad de producto"
                                      />
                                    </div>
                                  </v-col>
                                  <v-col class="pb-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <label>Cantidad<span
                                        class="required-field-form">*</span></label>
                                  </v-col>
                                  <v-col class="pt-0" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                    <v-radio-group hide-details v-model="addManually" @change="selectManually">
                                      <v-radio :value="setQuantity" label="Ingresar manualmente"></v-radio>
                                    </v-radio-group>
                                    <v-radio-group hide-details class="mt-1" v-model="currentElement.quantity" @change="selectQuantity">
                                      <v-radio :value="0.25" label="1/4"></v-radio>
                                      <v-radio :value="0.50" label="1/2"></v-radio>
                                      <v-radio :value="1" label="1"></v-radio>
                                    </v-radio-group>
                                  </v-col>
                                  <v-col v-if="addManually" cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                                    <div>
                                      <label class="input-label" for="street">Cantidad<span
                                          class="required-field-form">*</span></label>
                                      <v-text-field
                                          v-model="currentElement.quantity"
                                          id="street"
                                          :rules="required"
                                          type="number"
                                          min="0"
                                          hide-details
                                          outlined
                                          color="backofficeInput"
                                          placeholder="Ingresa la cantidad"
                                      />
                                    </div>
                                  </v-col>
                                  <!--                finaliza  seccion de producto y cantidad-->

                                  <!--                  inicia seccion indicaciones-->
                                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <div>
                                      <label class="input-label" for="street">Indicaciones<span
                                          class="required-field-form"></span></label>
                                      <v-text-field
                                          v-model="currentElement.indications"
                                          id="street"
                                          hide-details
                                          outlined
                                          color="backofficeInput"
                                          placeholder="Ingresa una indicación"
                                      />
                                    </div>
                                  </v-col>
                                  <!--                  finaliza seccion indicaciones-->

                                  <!--                  inicia seccion frecuencia-->

                                  <v-col class="pb-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <label>Frecuencia<span
                                        class="required-field-form">*</span></label>
                                    <div>
                                      <span>Selecciona uno o más opciones</span>
                                    </div>
                                  </v-col>
                                  <v-col class="pb-0 pt-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                      <!--                      inicia meses especificos-->
                                      <v-checkbox v-model="currentElement.monthsOfYearFreq" hide-details
                                                  :label="`Meses específicos del año`"></v-checkbox>
                                      <v-col v-if="currentElement.monthsOfYearFreq" cols="12" xs="12" sm="12"
                                             md="12" lg="12" xl="12">
                                        <v-row>
                                          <v-col class="pb-0 pt-0" v-for="(item, index) in months" :key="index" cols="12" xs="12" sm="6"
                                                 md="4"
                                                 lg="4" xl="4">
                                            <div v-if="selectedMonth && selectedMonth.length">
                                              <v-checkbox
                                                  hide-details
                                                  v-model="selectedMonth[index].value"
                                                  :label="`${item.value}`"
                                                  @change="selectMonths($event,item,index)"
                                              ></v-checkbox>
                                            </div>
                                          </v-col>
                                        </v-row>
                                      </v-col>
                                      <!--                     finaliza meses especificos-->

                                    <!--                      inicia cada x meses-->
                                    <v-col  class="pl-0 pb-0" cols="12" xs="12" sm="12" md="12" lg="12"
                                           xl="12">
                                      <v-checkbox style="margin-top: 4px" v-model="currentElement.monthsInterval" hide-details
                                                  :label="`Cada X meses`"></v-checkbox>
                                      <div v-if="currentElement.monthsInterval">
                                        <label class="input-label" for="street">Ingresa la cantidad de meses</label>
                                        <v-text-field
                                            v-model="currentElement.monthsInterval"
                                            id="street"
                                            type="number"
                                            :min="0"
                                            hide-details
                                            outlined
                                            color="backofficeInput"
                                            placeholder="Ingresa la cantidad de cada X meses"
                                        />
                                      </div>
                                    </v-col>
                                    <!--                      finaliza cada x meses-->

                                      <!--                      inicia cada X semanas-->
                                      <v-col class="pl-0" cols="12" xs="12" sm="12" md="12" lg="12"
                                             xl="12">
                                        <v-checkbox style="margin-top: 4px" v-model="currentElement.weeksInterval" hide-details
                                                    :label="`Cada X semanas`"></v-checkbox>
                                        <div v-if="currentElement.weeksInterval">
                                          <label class="input-label" for="street">Ingresa la cantidad de semanas</label>
                                          <v-text-field
                                              v-model="currentElement.weeksInterval"
                                              id="street"
                                              hide-details
                                              outlined
                                              type="number"
                                              :min="0"
                                              color="backofficeInput"
                                              placeholder="Ingresa la cantidad de cada X semanas"
                                          />
                                        </div>
                                      </v-col>
                                      <!--                      finaliza cada x semanas-->


                                      <!--                      inicia frecuencia diaria-->
                                      <v-col class="pb-0 pl-0 pt-0" cols="12" xs="12" sm="12" md="12"
                                             lg="12" xl="12">
                                        <div class="mt-4">
                                          <label>Cada cuantos días<span
                                              class="required-field-form">*</span></label>
                                        </div>
                                        <v-radio-group hide-details class="mt-0" v-model="currentElement.frequency"
                                                       @change="cleanFrequency">
                                          <v-row>
                                            <v-col cols="12" xs="12" sm="6" md="4"
                                                   lg="4" xl="4">
                                              <v-radio value="daily" label="Todos los días"></v-radio>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="4"
                                                   lg="4" xl="4">
                                              <v-radio value="alternate_day" label="Un dia si y un dia no"></v-radio>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="4"
                                                   lg="4" xl="4">
                                              <v-radio value="every_x_days" label="Cada X días"></v-radio>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="4"
                                                   lg="4" xl="4">
                                              <v-radio value="specific_week_days" label="Días especificos de la semana"></v-radio>
                                            </v-col>

                                            <!--                      inicia dias especificos de la semana-->
                                            <!--                                      <v-checkbox style="margin-top: 4px" v-model="currentElement.daysOfWeekFreq" hide-details
                                                                                              :label="`Días específicos de la semana`"></v-checkbox>-->

                                            <v-col v-if="currentElement.frequency === 'specific_week_days' " cols="12" xs="12" sm="12"
                                                   md="12" lg="12" xl="12">
                                              <v-row>
                                                <v-col class="pt-0" v-for="(item, index) in days" :key="index" cols="12" xs="12" sm="6"
                                                       md="4"
                                                       lg="4" xl="4">
                                                  <v-checkbox
                                                      style="margin-top: 4px"
                                                      v-if="selectedDays && selectedDays.length"
                                                      hide-details
                                                      v-model="selectedDays[index].value"
                                                      :label="`${item.value}`"
                                                      @change="selectDays($event,item,index)"
                                                  ></v-checkbox>
                                                </v-col>
                                              </v-row>
                                            </v-col>
                                            <!--                      finaliza dias especificos de la semana-->


                                            <v-col v-if="currentElement.frequency === 'every_x_days'" cols="12" xs="12" sm="12" md="12"
                                                   lg="12" xl="12">
                                              <div>
                                                <label class="input-label" for="street">Cada X días<span
                                                    class="required-field-form">*</span></label>
                                                <v-text-field
                                                    v-model="currentElement.daysInterval"
                                                    id="street"
                                                    :rules="required"
                                                    type="number"
                                                    :min="0"
                                                    hide-details
                                                    outlined
                                                    color="backofficeInput"
                                                    placeholder="Ingresa la cantidad de días"
                                                />
                                              </div>
                                            </v-col>
                                          </v-row>
                                        </v-radio-group>
                                      </v-col>

                                      <!--                       finaliza frecuencia diaria-->

                                      <!--                      inicia subfrecuencia diaria-->
                                      <div class="mt-4">
                                        <label>Cuantas veces al dia<span
                                            class="required-field-form">*</span></label>
                                      </div>
                                      <v-col class="pb-0 pl-0" cols="12" xs="12" sm="12"
                                             md="12" lg="12" xl="12">
                                        <v-radio-group class="mt-0" hide-details v-model="currentElement.subFrequency"
                                                       @change="cleanDaysInterval">
                                          <v-row>
                                            <v-col v-for="(item, index) in subFrequencies" :key="index" cols="12" xs="12" sm="6" md="4"
                                                   lg="4" xl="4">
                                              <v-radio :value="item.key" :label="item.value"></v-radio>
                                            </v-col>
                                          </v-row>
                                        </v-radio-group>
                                      </v-col>
                                      <v-col v-if="currentElement.subFrequency === 'more_than_three_times_per_day'" cols="12" xs="12"
                                             sm="12"
                                             md="12" lg="12" xl="12">
                                        <div>
                                          <label class="input-label" for="street">Más de 3 veces al día<span
                                              class="required-field-form">*</span></label>
                                          <v-text-field
                                              v-model="currentElement.subFrequencyNumber"
                                              id="street"
                                              :rules="required"
                                              type="number"
                                              :min="0"
                                              hide-details
                                              outlined
                                              color="backofficeInput"
                                              placeholder="Ingresa la cantidad"
                                          />
                                        </div>
                                      </v-col>
                                    <v-col v-if="currentElement.subFrequency === 'every_x_hours'" cols="12" xs="12"
                                           sm="12"
                                           md="12" lg="12" xl="12">
                                      <div>
                                        <label class="input-label" for="street">Cada X horas<span
                                            class="required-field-form">*</span></label>
                                        <v-text-field
                                            v-model="currentElement.hoursInterval"
                                            id="street"
                                            :rules="required"
                                            type="number"
                                            :min="0"
                                            hide-details
                                            outlined
                                            color="backofficeInput"
                                            placeholder="Ingresa la cantidad"
                                        />
                                      </div>
                                    </v-col>
                                      <!--                      finaliza subfrecuencia diaria-->

                                      <!--                      inicia Horario-->
                                      <div class="mt-4">
                                        <label>Horario<span
                                            class="required-field-form">*</span></label>
                                      </div>
                                      <v-col class="pl-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                        <v-row class="mt-0">
                                          <v-col cols="12" class="pt-0 " xs="12" sm="6" md="4" lg="4" xl="4">
                                            <v-checkbox
                                                style="margin-top: 2px"
                                                hide-details
                                                v-model="newDailyDose"
                                                value="morning"
                                                :label="'Mañana'"
                                                @change="selectDailyDoses('morning', $event)"
                                            ></v-checkbox>
                                          </v-col>
                                          <v-col cols="12" class="pt-0" xs="12" sm="6" md="4" lg="4" xl="4">
                                            <v-checkbox
                                                style="margin-top: 2px"
                                                hide-details
                                                v-model="newDailyDose"
                                                value="afternoon"
                                                :label="'Tarde'"
                                                @change="selectDailyDoses('afternoon', $event)"
                                            ></v-checkbox>
                                          </v-col>
                                          <v-col cols="12" class="pt-0" xs="12" sm="6" md="4" lg="4" xl="4">
                                            <v-checkbox
                                                style="margin-top: 2px"
                                                hide-details
                                                v-model="newDailyDose"
                                                value="night"
                                                :label="'Noche'"
                                                @change="selectDailyDoses('night', $event)"
                                            ></v-checkbox>
                                          </v-col>
                                          <v-col cols="12" class="pt-0" xs="12" sm="6" md="4" lg="4" xl="4">
                                            <v-checkbox
                                                style="margin-top: 2px"
                                                hide-details
                                                v-model="newDailyDose"
                                                value="other"
                                                :label="'Otro'"
                                                @change="selectDailyDoses('other', $event)"
                                            ></v-checkbox>
                                          </v-col>
                                        </v-row>
                                      </v-col>
                                      <v-col v-if="dailyDoseTimeArray.includes('other')" class="pt-0 pl-0"
                                             cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                        <div v-for="(time, index) in currentElement.doseTime" :key="index">
                                          <v-row class="align-center">
                                            <v-col cols="10" sm="11" md="11" lg="11" xl="11">
                                              <v-menu
                                                  :ref="`timeMenu${index}`"
                                                  :close-on-content-click="false"
                                                  v-model="menuStates[index]"
                                                  transition="scale-transition"
                                                  offset-y
                                                  max-width="290px"
                                                  min-width="290px"
                                              >
                                                <template v-slot:activator="{ on, attrs }">
                                                  <v-text-field
                                                      v-model="currentElement.doseTime[index]"
                                                      label="Escoge una hora"
                                                      prepend-icon="mdi-clock-time-four-outline"
                                                      readonly
                                                      :rules="required"
                                                      v-bind="attrs"
                                                      v-on="on"
                                                  ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                    v-model="currentElement.doseTime[index]"
                                                    full-width
                                                    @click:minute="$refs[`timeMenu${index}`][0].save(currentElement.doseTime[index])"
                                                ></v-time-picker>
                                              </v-menu>
                                            </v-col>

                                            <!-- Botón de eliminar -->
                                            <v-col cols="2" sm="1" md="1" lg="1" xl="1" class="text-right">
                                              <v-btn
                                                  v-if="index >= 0"
                                                  icon
                                                  color="error"
                                                  @click="removeTimeField(index)"
                                              >
                                                <v-icon>mdi-close</v-icon>
                                              </v-btn>
                                            </v-col>
                                          </v-row>
                                        </div>

                                        <!-- Botón para agregar nuevos campos -->
                                        <v-btn
                                            color="primary"
                                            text
                                            @click="addTimeField"
                                            class="mt-2"
                                        >
                                          <v-icon left>mdi-plus</v-icon>
                                          Agregar otra hora
                                        </v-btn>
                                      </v-col>
                                      <!--                      finaliza horario-->


                <!--                      inicia razon necesaria-->
                                    <div class="mt-4">
                                      <span>Otras opciones</span>
                                    </div>
                                      <v-checkbox style="margin-top: 4px" v-model="currentElement.asNeeded" hide-details
                                                  :label="`Razón necesaria`"></v-checkbox>
                                      <v-col v-if="currentElement.asNeeded" cols="12" xs="12" sm="12"
                                             md="12" lg="12" xl="12">
                                        <v-row>
                                          <v-col>
                                            <div>
                                              <label class="input-label" for="street">Mínima existencia<span
                                                  class="required-field-form">*</span></label>
                                              <v-text-field
                                                  v-model="currentElement.minimumStock"
                                                  id="street"
                                                  :rules="required"
                                                  type="number"
                                                  :min="0"
                                                  hide-details
                                                  outlined
                                                  color="backofficeInput"
                                                  placeholder="Ingresa la cantidad"
                                              />
                                            </div>
                                          </v-col>
                                        </v-row>
                                      </v-col>
                                      <!--                      inicia razon necesaria-->

                                  </v-col>

                                  <v-col class="pb-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <div class="mt-3">
                                        <span>Tipo de toma<span
                                            class="required-field-form">*</span></span>
                                    </div>
                                  </v-col>
                                  <v-col class="pb-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <v-row>
                                      <v-col class="pb-0" v-for="(item, index) in doseTypes" :key="index" cols="12" xs="12" sm="6"
                                             md="4"
                                             lg="4" xl="4">
                                        <v-radio-group class="mt-0" v-model="currentElement.doseType" @change="cleanDoseType">
                                          <v-radio :value="item.key" :label="item.value"></v-radio>
                                        </v-radio-group>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                  <v-col v-if="currentElement.doseType === 'periodic'" cols="12" xs="12" sm="12" md="12" lg="12"
                                         xl="12">
                                    <v-row>
                                      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <div>
                                          <label class="input-label" for="street">Fecha de inicio<span
                                              class="required-field-form">*</span></label>
                                          <v-text-field
                                              v-model="currentElement.startDateTime"
                                              id="street"
                                              :rules="required"
                                              hide-details
                                              outlined
                                              color="backofficeInput"
                                              placeholder="DD/MM/YYYY"
                                              @keyup="dateFormatter"
                                          />
                                        </div>
                                      </v-col>
                                      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                        <div>
                                          <label class="input-label" for="street">Fecha fin<span
                                              class="required-field-form">*</span></label>
                                          <v-text-field
                                              v-model="currentElement.endDateTime"
                                              id="street"
                                              :rules="required"
                                              hide-details
                                              outlined
                                              color="backofficeInput"
                                              placeholder="DD/MM/YYYY"
                                              @keyup="dateFormatter"
                                          />
                                        </div>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                  <v-col v-if="currentElement.doseType === 'until_end'" cols="12" xs="12" sm="12" md="12" lg="12"
                                         xl="12">
                                    <v-row>
                                      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"><label>Cantidad de pildoras<span
                                          class="required-field-form">*</span></label></v-col>
                                      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                        <div>
                                          <label class="input-label" for="street">Pildoras<span
                                              class="required-field-form">*</span></label>
                                          <v-text-field
                                              v-model="currentElement.pillCount"
                                              id="street"
                                              :rules="required"
                                              type="number"
                                              :min="0"
                                              hide-details
                                              outlined
                                              color="backofficeInput"
                                              placeholder="Ingresa la cantidad de pildoras"
                                          />
                                        </div>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
              </v-form>
            </template>
          </title-info-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import TitleInfoCard from "../../components/TitleInfoCard.vue";
import moduleMixin from "../../mixins/moduleMixin";
import {required} from "../../utils/validationRule";


export default {
  name: "kardexForm",
  mixins: [moduleMixin],
  components: {TitleInfoCard},
  props: {
    currentElement: {
      type: Object,
      default: () => {
      }
    },
    products: {
      type: Array,
      default: () => []
    },
    frequencies: {
      type: Array,
      default: () => []
    },
    subFrequencies: {
      type: Array,
      default: () => []
    },
    doseTypes: {
      type: Array,
      default: () => []
    },
    productUnits: {
      type: Array,
      default: () => []
    },
    dailyDoseTimeArray: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      menuStates: [],
      required,
      text: '',
      validForm: false,
      addManually: false,
      addManuallyDays: false,
      selectedMonth: [],
      selectedDays: [],
      days: [
        {key: 'monday', value: 'Lunes'},
        {key: 'tuesday', value: 'Martes'},
        {key: 'wednesday', value: 'Miércoles'},
        {key: 'thursday', value: 'Jueves'},
        {key: 'friday', value: 'Viernes'},
        {key: 'saturday', value: 'Sábado'},
        {key: 'sunday', value: 'Domingo'}
      ],
      months: [
        {key: 'january', value: 'Enero'},
        {key: 'february', value: 'Febrero'},
        {key: 'march', value: 'Marzo'},
        {key: 'april', value: 'Abril'},
        {key: 'may', value: 'Mayo'},
        {key: 'june', value: 'Junio'},
        {key: 'july', value: 'Julio'},
        {key: 'august', value: 'Agosto'},
        {key: 'september', value: 'Septiembre'},
        {key: 'october', value: 'Octubre'},
        {key: 'november', value: 'Noviembre'},
        {key: 'december', value: 'Diciembre'}
      ],
      menu2: false,
      time: null,
    }
  },
  watch: {
    'currentElement.daysOfWeekFreq'(newVal) {
      if (!newVal) {
        this.currentElement.daysOfWeek = null
      }
    },
    'currentElement.monthsOfYearFreq'(newVal) {
      this.fillDaysMonths()
      if (!newVal) {
        this.currentElement.monthsOfYear = null
      }
    },
  },
  mounted() {
    this.fillDaysMonths()
    if (this.currentElement.quantity && (this.currentElement.quantity !== 0.25 && this.currentElement.quantity !== 0.50 && this.currentElement.quantity !== 1)) {
      this.addManually = true
    }
  },
  computed: {
    setQuantity() {
      return this.currentElement.quantity !== 0.25 || this.currentElement.quantity !== 0.5 || this.currentElement.quantity !== 1;
    },
    setDaily() {
      return this.currentElement.dailyDoseTime !== 'morning' || this.currentElement.quantity !== 'afternoon' || this.currentElement.dailyDoseTime !== 'night';
    },
    newDailyDose: {
      get() {
        return this.dailyDoseTimeArray
      },
      set(value) {
        console.log(value, 'value')
      }
    }
  },
  methods: {
    addTimeField() {
      if (this.currentElement.doseTime === null) {
        this.currentElement.doseTime = []
      }
      this.currentElement.doseTime.push('')
    },

    removeTimeField(index) {
      this.currentElement.doseTime.splice(index, 1)
    },
    fillDaysMonths() {
      this.selectedMonth = []
      this.selectedDays = []
      if (Array.isArray(this.currentElement?.monthsOfYear)) {
        this.selectedMonth = this.months.map((item) => {
          return {
            value: this.currentElement.monthsOfYear.includes(item.key),
            name: item.key
          }
        })
      } else {
        this.selectedMonth = this.months.map((item) => {
          return {
            value: false,
            name: item.name
          }
        });
      }

      if (Array.isArray(this.currentElement?.daysOfWeek)) {
        this.selectedDays = this.days.map((item) => {
          return {
            value: this.currentElement?.daysOfWeek.includes(item.key),
            name: item.key
          }
        })
      } else {
        this.selectedDays = this.days.map((item) => {
          return {
            value: false,
            name: item.name
          }
        });
      }
    },
    inputForm(e) {
      this.$emit("validFormInput", e);
    },
    async getProductUnits() {
      this.$emit('updateProductUnit')
    },
    selectQuantity() {
      this.addManually = false
    },
    selectDailyDoses(value) {
      let isChecked = !this.dailyDoseTimeArray.includes(value)
      this.addManuallyDays = false
      this.handleDailyDoseChange(value, isChecked)
    },
    handleDailyDoseChange(value, isChecked) {
      const newArray = isChecked
          ? [...this.dailyDoseTimeArray, value]
          : this.dailyDoseTimeArray.filter(item => item !== value)
      this.$emit('UpdateDailyDoseTimeArray', newArray)
    },
    selectManually() {
      this.currentElement.quantity = 0
    },
    selectManuallyDays() {
      this.currentElement.dailyDoseTime = null
    },
    cleanDaysInterval() {
      this.currentElement.subFrequencyNumber = null
      this.currentElement.hoursInterval = null
      this.currentElement.dailyDoseTime = null
      this.addManuallyDays = false
    },
    cleanFrequency() {
      this.currentElement.daysOfWeek = null
      this.fillDaysMonths()
      this.cleanDaysInterval()
    },
    selectDays(event, item) {
      if (!Array.isArray(this.currentElement?.daysOfWeek) || !this.currentElement.daysOfWeek.length) {
        this.currentElement.daysOfWeek = [];
      }

      const foundItemIndex = this.currentElement.daysOfWeek.findIndex((key) => key === item.key);
      if (foundItemIndex !== -1) {
        this.currentElement.daysOfWeek.splice(foundItemIndex, 1);
      } else {
        this.currentElement.daysOfWeek.push(item.key);
      }

      const foundItemIndexSelectedDays = this.selectedDays.findIndex(({name}) => name === item.key)
      if (foundItemIndexSelectedDays !== -1) {
        return
      }
      this.selectedDays.push({name: item.value, value: true})
    },
    selectMonths(event, item) {
      try {
        if (!Array.isArray(this.currentElement?.monthsOfYear) || !this.currentElement.monthsOfYear.length) {
          this.currentElement.monthsOfYear = [];
        }

        const foundItemIndex = this.currentElement.monthsOfYear.findIndex((key) => key === item.key);
        if (foundItemIndex !== -1) {
          this.currentElement.monthsOfYear.splice(foundItemIndex, 1);
        } else {
          this.currentElement.monthsOfYear.push(item.key);
        }

        const foundItemIndexSelectedMonth = this.selectedMonth.findIndex(({name}) => name === item.key)
        if (foundItemIndexSelectedMonth !== -1) {
          return
        }
        this.selectedMonth.push({name: item.value, value: true})
      } catch (error) {
        console.log(error)
      }
    },
    formatDate(value) {
      if (!value) {
        return value
      }
      value = value.replace(/[^\d/]/g, '');
      const dateValue = value.replace(/\D/g, '')
      const dateValueLength = dateValue?.length
      if (dateValueLength < 2) return dateValue
      if (dateValueLength < 4) {
        return `${dateValue.slice(0, 2)}/${dateValue.slice(2, 4)}`
      }
      return `${dateValue.slice(0, 2)}/${dateValue.slice(2, 4)}/${dateValue.slice(4, 8)}`
    },
    dateFormatter(event) {
      event.target.value = this.formatDate(event.target.value)
    },
    cleanDoseType() {
      this.currentElement.startDateTime = null
      this.currentElement.endDateTime = null
      this.currentElement.pillCount = null
    }
  }
}
</script>

<style scoped>
.read-only-text {
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  font-size: 16px;
  letter-spacing: normal;
  max-width: 100%;
  text-align: left;
}

.read-only-text-child {
  display: flex;
  flex-direction: column;
  height: auto;
  flex-grow: 1;
  flex-wrap: wrap;
  min-width: 0;
  width: 100%;
}
</style>
